<template>
    <div class="absolute flex-col top-0 left-0 flex justify-center items-center w-full h-full">
        <loader />
        <p class="mt-8"><strong>Probíhá přihlašování...</strong></p>
    </div>
    
</template>

<script>
import Loader from "./Loader.vue";

export default {
    name: "LoggingIn",
    components: {
        Loader,
    },
};
</script>